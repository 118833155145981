import dynamic from "next/dynamic";
import Head from "next/head";
import { getData } from "../lib/getData";
import { getImage } from "../lib/getImage";

const HomePage = dynamic(import("../screens/Home"), {
  ssr: false,
  loading: () => (
    <div className="fixed inset-0 bg-gradient-to-r from-purple-400 to-blue-500">
      <div className="h-full w-full bg-white opacity-60"></div>
      <div className="h-full w-full flex items-center justify-center">
        <div className="grid grid-cols-3 gap-4">
          <div className="w-20 h-20 rounded-full bg-white animate-pulse"></div>
          <div className="w-20 h-20 rounded-full bg-white animate-pulse"></div>
          <div className="w-20 h-20 rounded-full bg-white animate-pulse"></div>
          <div className="w-20 h-20 rounded-full bg-white animate-pulse"></div>
          <div className="w-20 h-20 rounded-full bg-white animate-pulse"></div>
          <div className="w-20 h-20 rounded-full bg-white animate-pulse"></div>
        </div>
      </div>
    </div>
  ),
});

export default function Home({ menu, home, locale }: any) {
  return (
    <>
      <Head>
        <title>Ekival</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={getImage(home?.company?.favicon)} />

        <meta name="description" content={home?.company?.description} />
        <meta name="keywords" content="Ekival, ada, blockchain, cardano, mobile money" />
        <meta name="author" content="Uptodate Developers" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="nositelinkssearchbox" />

        <meta property="og:locale" content="en" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={home?.company?.name} />
        <meta property="og:description" content={home?.company?.description} />
        <meta property="og:url" content="https://ekival.com" />
        <meta property="og:site_name" content={home?.company?.name} />
        <meta property="og:image" content={getImage(home?.company?.ogimage)} />
        <meta property="og:image:secure_url" content={getImage(home?.company?.ogimage)} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Ekival" />
        <meta name="twitter:title" content={home?.company?.name} />
        <meta name="twitter:image" content={getImage(home?.company?.ogimage)} />
      </Head>
      <HomePage menu={menu} home={home} />
    </>
  );
}

export async function getStaticProps() {
  const menu = await getData("menu");
  const home = await getData("home");

  return {
    props: {
      menu: menu.data,
      home: home.data,
    },
    revalidate: 60,
  };
}
